* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: Inter, 'Noto Sans JP', 'Noto Sans KR', 'Noto Sans SC',
    'Noto Sans TC', sans-serif;
}

a {
  text-decoration: none;
}

